<template>
    <div class='stage-discrepancies'>
      <kendo-splitter
      class="stage-discrepancies-main"
      :panes="hSplitter1.panes"
      :orientation="hSplitter1.orientation"
      :scrollable="hSplitter1.scrollable">
      <kendo-splitter
        :panes="hSplitter2.panes"
        :orientation="hSplitter2.orientation"
        :scrollable="hSplitter2.scrollable">
        <kendo-splitter
        :panes="vSplitter4.panes"
        :orientation="vSplitter4.orientation"
        :scrollable="vSplitter4.scrollable">
        <SplitterContainer title="Decision (ID: 1234)" component="ActionsStageDiscrepancies"/>
        <SplitterContainer title="Annotations" component="Annotations"/>
        </kendo-splitter>
        <SplitterContainer title="Citations List with Discrepancies" component="CitationListDiscrepancies"/>
      </kendo-splitter>
      <kendo-splitter
      :panes="vSplitter2.panes"
      :orientation="vSplitter2.orientation"
      :scrollable="vSplitter2.scrollable">
        <kendo-splitter
        :panes="hSplitter4.panes"
        :orientation="hSplitter4.orientation"
        :scrollable="hSplitter4.scrollable">
          <SplitterContainer title="Full Text" component="FullText" detach="true"/>
        </kendo-splitter>
      </kendo-splitter>
    </kendo-splitter>
    </div>
</template>

<script>
import SplitterContainer from '@/containers/SplitterContainer'

export default {
  name: 'data-discrepancies',
  components: {
    SplitterContainer
  },
  data () {
    return {
      isProcess: false,
      hSplitter1: {
        scrollable: true,
        orientation: 'horizontal',
        panes: [
          { collapsible: true, size: '60%' },
          { collapsible: true }
        ]
      },
      hSplitter2: {
        scrollable: false,
        orientation: 'horizonal',
        panes: [
          { collapsible: true, size: '40%' },
          { collapsible: true }
        ]
      },
      hSplitter3: {
        scrollable: true,
        orientation: 'horizontal',
        panes: [
          { collapsible: true, size: '40%' },
          { collapsible: true }
        ]
      },
      hSplitter4: {
        scrollable: true,
        orientation: 'horizontal',
        panes: [
          { collapsible: true, size: '50%' },
          { collapsible: true }
        ]
      },
      vSplitter1: {
        scrollable: true,
        orientation: 'vertical',
        panes: [
          { collapsible: true, size: '50%' },
          { collapsible: true, size: '50%' }
        ]
      },
      vSplitter2: {
        scrollable: true,
        orientation: 'vertical',
        panes: [
          { collapsible: true, size: '30%' },
          { collapsible: true }
        ]
      },
      vSplitter4: {
        scrollable: true,
        orientation: 'vertical',
        panes: [
          { collapsible: true, size: '50%' },
          { collapsible: true }
        ]
      }
    }
  }
}
</script>
